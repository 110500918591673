//#region Imports
import React from "react";
import { Typography, Skeleton, Grid, Divider } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCompassEnrolledPrograms } from "../../services/hooks/programsHook";
import { useGetCurrentDsaRegisteredPartners } from "../../services/hooks/dsaHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";
import DsaListItem from "./DsaListItem";
//#endregion

export default function ActivitiesEnrolled() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();

  const query = useCompassEnrolledPrograms(activeStudent?.studentId);
  const programs = query?.data?.data;

  const dsaRegisteredQuery = useGetCurrentDsaRegisteredPartners(
    activeStudent?.studentId
  );
  const dsaRegistered = dsaRegisteredQuery?.data?.data;

  //#region Render
  return (
    <>
      {query.isError && (
        <Error message="There was an issue retrieving current activities." />
      )}
      {query.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!query.isLoading &&
        programs?.length === 0 &&
        dsaRegistered?.length === 0 && (
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  mt: 3,
                  color: theme?.palette?.text?.secondary,
                }}
                gutterBottom
              >
                <em>
                  When{" "}
                  {user?.role === "guardian"
                    ? `${activeStudent?.firstName} is`
                    : "you are"}{" "}
                  enrolled in any currently-running activities, you'll see a
                  list of them&nbsp;here...
                </em>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon
                icon={faList}
                size="10x"
                color={alpha(theme?.palette?.text?.primary ?? "#777", 0.25)}
              />
            </Grid>
          </Grid>
        )}
      {!query.isLoading &&
        programs?.length > 0 &&
        programs?.map((p, index) => {
          return (
            <ActivityListItem program={p} pid={p?.programId} key={index} />
          );
        })}
      {!query.isLoading &&
        programs?.length > 0 &&
        dsaRegistered?.length > 0 && (
          <Divider variant="middle" sx={{ my: 6 }} />
        )}
      {!query?.isLoading && dsaRegistered?.length > 0 && (
        <>
          <Typography variant="lede" component="h2" sx={{ mt: 2 }}>
            Programs with Data Sharing Agreements
          </Typography>
          <Typography component="div" variant="caption" sx={{ mb: 2 }}>
            Our partners are dedicated to enhancing our students' social,
            emotional, and academic well-being. With parent/guardian permission,
            they use our student's data to guide and refine the content and
            delivery of their programs. Participation in these programs requires
            sharing your student's data, which allows them to tailor the
            experience to our students.
          </Typography>
          <Grid container spacing={2}>
            {dsaRegistered?.map((dsa, index) => {
              return (
                <DsaListItem
                  key={index}
                  partner={dsa.partner}
                  activeStudent={activeStudent}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
  //#endregion
}
