//#region Imports
import React from "react";
import { Typography, Skeleton, Grid, Divider } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceConfused } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useUser } from "../../services/contexts/userContext";
import { useCompassEligiblePrograms } from "../../services/hooks/programsHook";
import { useGetEligibleDsaPartners } from "../../services/hooks/dsaHook";
import { useCompassEnrolledPrograms } from "../../services/hooks/programsHook";
import { useGetWaitlistByStudentId } from "../../services/hooks/waitlistHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";
import DsaListItem from "./DsaListItem";
import { use } from "echarts";
//#endregion

export default function ActivitiesEligible() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();

  const query = useCompassEligiblePrograms(activeStudent?.studentId);
  const programs = query?.data?.data;

  const enrolledQuery = useCompassEnrolledPrograms(activeStudent?.studentId);
  const enrolled = enrolledQuery?.data?.data;

  const eligibleDsaPartnerQuery = useGetEligibleDsaPartners(
    activeStudent?.studentId
  );
  const eligibleDsaPartners = eligibleDsaPartnerQuery?.data?.data;

  const waitlistQuery = useGetWaitlistByStudentId(activeStudent?.studentId);
  const waitlist = waitlistQuery?.data?.data;

  //#region Render
  return (
    <>
      {query.isError && (
        <Error message="There was an issue retrieving eligible programs." />
      )}
      {query.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!query.isLoading &&
        programs?.length === 0 &&
        eligibleDsaPartners?.length === 0 && (
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  mt: 3,
                  color: theme?.palette?.text?.secondary,
                }}
                gutterBottom
              >
                <em>
                  Sorry, looks like there are no activities open for{" "}
                  {user?.role === "guardian" ? activeStudent?.firstName : "you"}{" "}
                  right&nbsp;now.
                </em>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FontAwesomeIcon
                icon={faFaceConfused}
                size="10x"
                color={alpha(theme?.palette?.text?.primary ?? "#777", 0.25)}
              />
            </Grid>
          </Grid>
        )}
      {!query?.isLoading &&
        programs?.map((p, index) => {
          const enrolledProgram = enrolled?.find(
            (e) => e?.programId === p?.programId
          );
          const isEnrolled = !!enrolledProgram;
          const matchingWaitlist = waitlist?.find(
            (w) => w?.programId === p?.programId
          );
          return (
            <ActivityListItem
              key={index}
              program={p}
              isEnrolled={isEnrolled}
              matchingProgram={enrolledProgram}
              matchingWaitlist={matchingWaitlist}
            />
          );
        })}
      {!query?.isLoading &&
        programs?.length > 0 &&
        eligibleDsaPartners?.length > 0 && (
          <Divider variant="middle" sx={{ my: 6 }} />
        )}
      {!query?.isLoading && eligibleDsaPartners?.length > 0 && (
        <>
          <Typography variant="lede" component="h2" sx={{ mt: 2 }}>
            Programs with Data Sharing Agreements
          </Typography>
          <Typography component="div" variant="caption" sx={{ mb: 2 }}>
            Our partners are dedicated to enhancing our students' social,
            emotional, and academic well-being. With parent/guardian permission,
            they use our student's data to guide and refine the content and
            delivery of their programs. Participation in these programs requires
            sharing your student's data, which allows them to tailor the
            experience to our students.
          </Typography>
          <Grid container spacing={2}>
            {eligibleDsaPartners?.map((partner, index) => {
              return (
                <DsaListItem
                  key={index}
                  partner={partner}
                  activeStudent={activeStudent}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
  //#endregion
}
