import {
  blue,
  lightBlue,
  deepPurple,
  deepOrange,
  brown,
  green,
  grey,
  indigo,
  red,
  yellow,
  lightGreen,
  amber,
  orange,
  cyan,
} from "@mui/material/colors";
import { alpha, darken, lighten } from "@mui/material/styles";
import "@fontsource-variable/open-sans/standard.css";
import "@fontsource-variable/overpass";
import "@fontsource/berkshire-swash/latin-400.css";
import { blob } from "./services/utilities";

const openSansTypography = {
  name: "openSans",
  fontFamily: "'Open Sans Variable', sans-serif",
  h1: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: "'wdth' 75",
    fontWeight: 700,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
    fontSize: "3.5rem",
  },
  h3: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h4: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h5: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
  h6: {
    fontFamily: "'Open Sans Variable', sans-serif",
    fontVariationSettings: '"wdth" 75',
    fontWeight: 700,
  },
};

const overpassTypography = {
  name: "overpass",
  fontFamily: "'Open Sans Variable', sans-serif",
  h1: {
    fontFamily: "'Overpass Variable', sans-serif",
    fontWeight: 900,
    fontSize: "4rem",
  },
  h2: {
    fontFamily: "'Overpass Variable', sans-serif",
    fontWeight: 900,
    fontSize: "3.5rem",
  },
  h3: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h4: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h5: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
  h6: { fontFamily: "'Overpass Variable', sans-serif", fontWeight: 900 },
};

const neutral = {
  darkMode: {
    main: grey[100],
    light: "#fff",
    lightest: "#fff",
    dark: grey[700],
    darkest: "#000",
    contrast: grey[900],
    contrastText: grey[900],
  },
  lightMode: {
    main: grey[700],
    light: grey[300],
    lightest: "#fff",
    dark: grey[900],
    darkest: "#000",
    contrast: "#fff",
    contrastText: "#fff",
  },
};
const standardMoods = {
  blueZone: {
    main: blue[800],
    secondary: blue[900],
    contrastText: "#fff",
  },
  greenZone: {
    main: green[700],
    secondary: green[800],
    contrastText: "#fff",
  },
  yellowZone: {
    main: yellow[600],
    secondary: yellow[500],
    contrastText: grey[900],
    alt: yellow[700],
    secondaryAlt: yellow[500],
  },
  redZone: {
    main: red[700],
    secondary: red[800],
    contrastText: "#fff",
  },
};

const standardBadge = {
  frill: grey[700],
  circle: lightBlue[600],
  ribbon: lightBlue[700],
  ribbonEdge: "#fff",
  number: "#fff",
  decoration: "#fff",
  shadow: "#000",
};

const lede = {
  fontSize: "1.5rem",
  fontStyle: "italic",
  fontWeight: 300,
  "& em": {
    fontStyle: "normal",
  },
};

const interests = {
  heart: red[800],
  thumbs: "#efa226",
  svgIcon: {
    fontSize: "2em",
    maxWidth: 36,
    maxHeight: 36,
    margin: 0,
  },
  svgFilterIcon: {
    display: "block",
    width: 20,
    fontSize: "2em",
    maxWidth: 36,
    maxHeight: 36,
    margin: 0,
  },
  svgRatingIcon: {
    fontSize: "2em",
    width: "24px",
    height: "24px",
    maxWidth: 36,
    maxHeight: 36,
    margin: 0,
  },
};

const bigIconButton = (color, background) => {
  const shadow = background ? background : "rgba(0, 0, 0, 0)";

  return {
    props: { variant: "bigIcon" },
    style: {
      lineHeight: "1rem",
      textAlign: "left",
      color: color, // usually same as theme.palette.neutral.light
      borderTopLeftRadius: "calc(25px + 0.75rem)",
      borderBottomLeftRadius: "calc(25px + 0.75rem)",
      padding: "0.75rem",
      textShadow: `1px  1px 0 ${shadow}, 
                  -1px  1px 0 ${shadow}, 
                   1px -1px 0 ${shadow}, 
                  -1px -1px 0 ${shadow},
                   0 0 0.5rem ${shadow}`,
      "& .MuiButton-startIcon": {
        "& > svg": {
          fontSize: 50,
          "& path": {
            stroke:
              shadow === "transparent"
                ? "rgba(0, 0, 0, 0)"
                : alpha(shadow, 0.25), // usually same as theme.palette.background.default
            strokeWidth: "40px",
            paintOrder: "stroke",
          },
        },
      },
    },
  };
};

const classicHeader = (page, backgroundColor, color) => {
  return {
    background: `url(${blob}header-${page}.svg) no-repeat 279px 0px, linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor} 116px, ${grey[50]} 116px, ${grey[50]} 100%)`,
    backgroundSize: "186px, 100%",
    "@media (max-width: 1200px)": {
      background: `url(${blob}header-${page}.svg) no-repeat 0px 56px, linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor} 150px, ${grey[50]} 150px, ${grey[50]} 100%)`,
      backgroundSize: "150px, 100%",
    },
    "& #compass-back-button": {
      position: "absolute",
      top: 4,
      marginLeft: "8.5rem",
      "@media (min-width: 1480px) and (max-width: 1750px)": {
        marginLeft: "calc( ((100vw - 1750px) * -1) / 2)",
      },
      "@media (min-width: 1750px)": {
        marginLeft: 0,
      },
      "@media (max-width: 1200px)": {
        top: 64,
      },
    },
    "& .MuiTypography-h1:first-of-type": {
      color: color,
      marginBottom: "1.5rem",
      marginLeft: "8.5rem",
      whiteSpace: "nowrap",
      maxWidth: "calc(100vw - 8.5rem)",
      height: "1.2em",
      overflow: "hidden",
      "@media (min-width: 1480px) and (max-width: 1750px)": {
        marginLeft: "calc( ((100vw - 1750px) * -1) / 2)",
      },
      "@media (min-width: 1750px)": {
        marginLeft: 0,
      },
    },
  };
};

export const themes = [
  /*
██████╗ ███████╗███████╗ █████╗ ██╗   ██╗██╗  ████████╗
██╔══██╗██╔════╝██╔════╝██╔══██╗██║   ██║██║  ╚══██╔══╝
██║  ██║█████╗  █████╗  ███████║██║   ██║██║     ██║   
██║  ██║██╔══╝  ██╔══╝  ██╔══██║██║   ██║██║     ██║   
██████╔╝███████╗██║     ██║  ██║╚██████╔╝███████╗██║   
╚═════╝ ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝            */
  {
    name: "Default",
    palette: {
      mode: "dark",
      background: {
        default: "#3746af", // blurple - average of colors in blue to deepPurple low poly gradient background image
        paper: blue[700],
        neutral: "rgba(0, 0, 0, 0.6)",
        neutralAlt: "rgba(255, 255, 255, 0.9)",
        array: [
          { background: grey[400], color: blue[900] },
          { background: lightBlue[200], color: blue[900] }, // primary
          { background: lightGreen.A200, color: blue[900] }, // secondary
          { background: amber.A200, color: blue[900] }, // tertiary
          { background: deepPurple[900], color: "#ffffff" },
          { background: blue[900], color: lightBlue[200] },
          { background: deepPurple[200], color: "#000000" },
        ],
      },
      primary: {
        main: lightBlue[200],
        // also used manually for markdown link styles in index.css
        text: lightBlue[100],
        contrastText: blue[900],
      },
      secondary: {
        main: lightGreen["A100"],
        text: lightGreen["A100"],
        contrastText: green[900],
      },
      tertiary: {
        main: amber["A200"],
        text: amber["A100"],
        contrastText: amber[700],
      },
      neutral: neutral.darkMode,
      error: {
        main: red[400],
        text: red[300],
      },
      warning: {
        main: orange[500],
        text: orange[300],
      },
      info: {
        main: blue[500],
        text: blue[300],
      },
      success: {
        main: green[500],
        text: green[300],
      },
      charts: {
        first: lightBlue["A100"], //primary
        second: lightGreen["A100"], //secondary
        third: amber["A200"], //tertiary
        fourth: red[400], //quaternary
        fifth: green[500], //quinary
      },
      nav: {
        appBar: blue[900],
        main: "rgba(0, 0, 0, 0)",
        light: "#2c2c33",
        dark: "#1c1c1c",
        contrastText: "#fff",
        logoText: "#fff",
        logoO: blue[300],
        arc: "#ccc",
        hashes: "#000",
        arrowLight: "#de5433",
        arrowDark: "#a84026",
        selected: {
          background: "linear-gradient(90deg, #1e88e5 30%, #a5d6a7 100%)",
        },
        hover: {
          background: alpha(blue[900], 0.32),
        },
      },
      text: {
        primaryAlt: "rgba(0, 0, 0, 0.87)",
        secondaryAlt: "rgba(0, 0, 0, 0.6)",
      },
      moods: standardMoods,
      badges: {
        frill: deepPurple[900],
        circle: deepPurple[600],
        ribbon: lightBlue[800],
        ribbonEdge: "#fff",
        number: "#fff",
        decoration: "#fff",
        shadow: "#000",
      },
      interests: interests,
      feed: {
        goal: {
          background: `${lightGreen[900]} url(${blob}default-feed-goal-bg.svg) no-repeat !important`,
          backgroundSize: "cover",
        },
      },
    },
    typography: overpassTypography,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            height: "100vh",
            background: `${blue[800]} url(${blob}bg-low-poly-blue-purple.svg) no-repeat center center`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 650,
            "&.MuiButton-outlined": {
              background: "rgba(0,0,0,0.075)",
              backdropFilter: "blur(24px)",
            },
          },
          containedPrimary: {
            color: blue[900],
            "&:hover": {
              backgroundColor: lightBlue[100],
            },
          },
          containedSecondary: {
            color: blue[900],
            "&:hover": {
              backgroundColor: lightGreen[100],
            },
          },
          outlinedSecondary: {
            "&:hover": {
              color: lightGreen["A200"],
              borderColor: lightGreen["A200"],
            },
          },
          textPrimary: {
            "&:hover": {
              backgroundColor: alpha(lightBlue[900], 0.32),
            },
          },
          textSecondary: {
            "&:hover": {
              backgroundColor: alpha(lightGreen[50], 0.08),
              color: lightGreen["A200"],
            },
          },
        },
        variants: [bigIconButton("#fff", "#3746af")],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              backdropFilter: "blur(8px)",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "rgba(255,255,255,0.075)",
            backdropFilter: "blur(24px)",
            border: "1px solid rgba( 255, 255, 255, 0.2 )",
            "@supports not (backdrop-filter: blur(24px))": {
              background: `linear-gradient(90deg, ${alpha(
                blue[800],
                0.9
              )} 0%, ${alpha(deepPurple[800], 0.9)} 100%)`,
              border: "none",
            },
          },
          elevation0: {
            background: "rgba(0, 0, 0, 0)",
            backdropFilter: "none",
            border: "none",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "& strong": {
              fontWeight: 800,
            },
          },
        },
        variants: [
          {
            props: { variant: "lede" },
            style: lede,
          },
        ],
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#fff",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#fff",
            background: alpha(grey[500], 0.125),
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: `${green[300]}`,
              color: green[900],
              fontWeight: 800,
            },
            "&.MuiPickersDay-today": {
              border: `2px solid #fff`,
            },
          },
        },
      },
    },
  },
  /*
███████╗ ██████╗ ██████╗ ███████╗███████╗████████╗
██╔════╝██╔═══██╗██╔══██╗██╔════╝██╔════╝╚══██╔══╝
█████╗  ██║   ██║██████╔╝█████╗  ███████╗   ██║   
██╔══╝  ██║   ██║██╔══██╗██╔══╝  ╚════██║   ██║   
██║     ╚██████╔╝██║  ██║███████╗███████║   ██║   
╚═╝      ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝   ╚═╝                */
  {
    name: "Forest",
    palette: {
      mode: "dark",
      background: {
        default: "#105157",
        paper: "#f3ac87",
        neutral: "rgba(0, 0, 0, 0.6)",
        neutralAlt: "rgba(255, 255, 255, 0.9)",
        array: [
          { background: grey[400], color: "#105157" },
          { background: "#69ba6b", color: "#105157" }, // primary
          { background: "#7a5243", color: "#fcdc75" }, // secondary
          { background: "#fcdc75", color: "#105157" }, // tertiary
          { background: "#a5c5c5", color: "#000000" },
          { background: "#308e60", color: "#ffffff" },
          { background: "#ffffff", color: "#0f5256" },
        ],
      },
      primary: {
        main: "#69ba6b",
        // also used manually for markdown link styles in index.css
        text: "#96cf97",
        contrastText: "#rgba(16, 81, 87)",
      },
      secondary: {
        main: "#f3ac87", //"#7a5243",
        text: "#9b7970",
        contrastText: "#7a5243",
      },
      tertiary: {
        main: "#fcdc75",
        text: "#fde69e",
        contrastText: "#105157",
      },
      neutral: neutral.darkMode,
      error: {
        main: red[400],
        text: red[300],
      },
      warning: {
        main: orange[500],
        text: orange[300],
      },
      info: {
        main: blue[500],
        text: blue[300],
      },
      success: {
        main: green[500],
        text: green[300],
      },
      charts: {
        first: blue[500], //primary
        second: yellow["A200"], //secondary
        third: cyan["A400"], //tertiary
        fourth: red[400], //quaternary
        fifth: green["A400"], //quinary
      },
      nav: {
        appBar: "#31785f",
        main: "rgba(0, 0, 0, 0)",
        light: "#2b3b33",
        dark: "#212b26",
        contrastText: "#d9d9d9",
        logoText: "#fff",
        logoO: "#69ba6b",
        arc: "#ddd",
        hashes: "#aaa",
        arrowLight: "#de5433",
        arrowDark: "#a84026",
        selected: {
          background: `url(${blob}leaf.svg) no-repeat calc(100% - 8px) center , linear-gradient(90deg, #69ba6b 30%, rgba(0, 0, 0, 0) 90%)`,
          color: "#fff",
          "& svg path": {
            fill: "#fff",
          },
          "& span": {
            fontWeight: 750,
          },
        },
        hover: {
          background: alpha("#2b3b33", 0.8),
        },
      },
      text: {
        primaryAlt: "rgba(0, 0, 0, 0.87)",
        secondaryAlt: "rgba(0, 0, 0, 0.6)",
      },
      moods: standardMoods,
      badges: {
        frill: darken("#7a5243", 0.8),
        circle: "#7a5243",
        ribbon: "#69ba6b",
        ribbonEdge: "#fff",
        number: "#fff",
        decoration: "#f3ac87",
        shadow: "#000",
      },
      interests: interests,
      feed: {
        goal: {
          backgroundColor: `${darken("#105157", 0.2)} !important`,
          backgroundImage: `linear-gradient(135deg,
            ${darken("#105157", 0.2)} 30%,
            ${darken("#105157", 0.3)} 30%,
            ${darken("#105157", 0.3)} 70%,
            ${darken("#105157", 0.4)} 70%,
            ${darken("#105157", 0.4)} 90%,
            ${darken("#105157", 0.5)} 90%,
            ${darken("#105157", 0.5)} 100%) !important`, // background.default
          backgroundSize: "cover",
        },
        news: {
          "& svg": { color: "#105157" },
        },
        calendar: {
          "& button > div:first-child svg": { color: "#7a5243" },
        },
      },
    },
    typography: {
      fontFamily: "'Open Sans Variable', sans-serif",
      h1: {
        fontFamily: "'Berkshire Swash', sans-serif",
        fontSize: "4rem",
        marginTop: "6.5rem",
        textShadow: "0 0 0.125rem #105157",
        "@media (max-width: 1200px)": {
          marginTop: "3rem",
        },
      },
      h2: {
        fontFamily: "'Berkshire Swash', sans-serif",
        fontSize: "3.5rem",
      },
      h3: { fontFamily: "'Berkshire Swash', sans-serif" },
      h4: { fontFamily: "'Berkshire Swash', sans-serif" },
      h5: { fontFamily: "'Berkshire Swash', sans-serif" },
      // h6 uses the default font
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            height: "100vh",
            backgroundColor: "#0f5256",
            backgroundImage: `url(${blob}trees-bush.svg), url(${blob}bg-enchanted-forest.svg)`,
            backgroundPosition: "100% 100%, center top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "400px, cover",
            backgroundAttachment: "fixed, local",
          },
          "@media (max-width: 1200px)": {
            body: {
              backgroundSize: "200px, cover",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 650,
            "&.MuiButton-outlined": {
              background: "rgba(0,0,0,0.075)",
              backdropFilter: "blur(24px)",
            },
            "&#compass-back-button": {
              marginTop: "7.25rem",
              marginBottom: "-7.25rem",
            },
          },
          containedPrimary: {
            color: "#fff",
            "&:hover": {
              backgroundColor: "#589c5a",
            },
          },
          containedSecondary: {
            color: "#fff",
            "&:hover": {
              backgroundColor: "#664538",
            },
          },
          outlinedSecondary: {
            color: "#f3ac87",
            borderColor: alpha("#f3ac87", 0.48),
            "&:hover": {
              color: "#f3ac87",
              backgroundColor: alpha("#664538", 0.32),
            },
          },
          textPrimary: {
            background: alpha("#105157", 0.64), // in most cases this is invisible, but helps ensure contrast
            "&:hover": {
              backgroundColor: alpha("#589c5a", 0.16),
            },
          },
          textSecondary: {
            color: "#f3ac87",
            "&:hover": {
              color: "#f3ac87",
              backgroundColor: alpha("#664538", 0.32),
            },
          },
        },
        variants: [bigIconButton("#fff", "#105157")],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              background: alpha("#105157", 0.64),
              backdropFilter: "blur(4px)",
            },
            "& .MuiInputLabel-root": {
              background: alpha("#105157", 0.64),
              backdropFilter: "blur(4px)",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "rgba(255,255,255,0.075)",
            backdropFilter: "blur(24px)",
            border: "1px solid rgba( 255, 255, 255, 0.2 )",
            "@supports not (backdrop-filter: blur(24px))": {
              background: alpha("#31785f", 0.8),
              border: "none",
            },
          },
          elevation0: {
            background: "rgba(0, 0, 0, 0)",
            backdropFilter: "none",
            border: "none",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "& strong": {
              fontWeight: 800,
            },
          },
        },
        variants: [
          {
            props: { variant: "lede" },
            style: {
              ...lede,
              fontWeight: 700,
              textShadow: "0 0 0.125rem #105157",
              "& strong": {
                fontWeight: 900,
                color: lighten("#69ba6b", 0.6),
                textShadow: "0 0 0.5rem #000",
              },
            },
          },
        ],
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#f3ac87",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#fff",
            background: alpha(grey[500], 0.125),
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: `${green[300]}`,
              color: green[900],
              fontWeight: 800,
            },
            "&.MuiPickersDay-today": {
              border: `2px solid #fff`,
            },
          },
        },
      },
    },
  },
  /*
███╗   ███╗██╗██████╗ ███╗   ██╗██╗ ██████╗ ██╗  ██╗████████╗
████╗ ████║██║██╔══██╗████╗  ██║██║██╔════╝ ██║  ██║╚══██╔══╝
██╔████╔██║██║██║  ██║██╔██╗ ██║██║██║  ███╗███████║   ██║   
██║╚██╔╝██║██║██║  ██║██║╚██╗██║██║██║   ██║██╔══██║   ██║   
██║ ╚═╝ ██║██║██████╔╝██║ ╚████║██║╚██████╔╝██║  ██║   ██║   
╚═╝     ╚═╝╚═╝╚═════╝ ╚═╝  ╚═══╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝     */
  {
    name: "Midnight",
    palette: {
      mode: "dark",
      background: {
        default: grey[900],
        paper: grey[800],
        neutral: "rgba(0, 0, 0, 0.6)",
        neutralAlt: "rgba(255, 255, 255, 0.9)",
        array: [
          { background: grey[100], color: indigo[800] },
          { background: yellow[200], color: indigo[900] }, // primary
          { background: indigo[200], color: indigo[900] }, // secondary
          { background: grey[300], color: indigo[900] }, // tertiary
          { background: yellow[600], color: "#000000" },
          { background: blue[900], color: lightBlue[200] },
          { background: deepPurple[900], color: "#ffffff" },
        ],
      },
      primary: {
        main: yellow[200],
        // also used manually for markdown link styles in index.css
        text: yellow[100],
        contrastText: indigo[900],
      },
      secondary: {
        main: indigo[200],
        text: indigo[100],
        contrastText: indigo[900],
      },
      tertiary: {
        main: grey[400],
        text: grey[300],
        contrastText: indigo[900],
      },
      neutral: neutral.darkMode,
      error: {
        main: red[400],
        text: red[300],
      },
      warning: {
        main: orange[500],
        text: orange[300],
      },
      info: {
        main: blue[500],
        text: blue[300],
      },
      success: {
        main: green[500],
        text: green[300],
      },
      charts: {
        first: yellow[200], //primary
        second: blue[500], //secondary
        third: deepPurple[300], //tertiary
        fourth: red[400], //quaternary
        fifth: green[500], //quinary
      },
      nav: {
        appBar: "#121212",
        main: "#121212",
        light: "#191919",
        dark: "#1c1c1c",
        contrastText: "#fff",
        logoText: grey[600],
        logoO: yellow[200],
        arc: "#aaa",
        hashes: alpha(yellow[500], 0.5),
        arrowLight: yellow[600],
        arrowDark: yellow[800],
        selected: {
          background: alpha(yellow[500], 0.08),
          borderRight: `4px solid ${alpha(yellow[200], 0.75)}`,
          color: yellow[200],
          "& svg path": {
            fill: yellow[200],
          },
          "& span": {
            fontWeight: 750,
          },
        },
        hover: {
          background: alpha(yellow[100], 0.16),
        },
      },
      text: {
        primaryAlt: "rgba(0, 0, 0, 0.87)",
        secondaryAlt: "rgba(0, 0, 0, 0.6)",
      },
      moods: standardMoods,
      badges: standardBadge,
      interests: interests,
      feed: {
        goal: {
          backgroundColor: `${darken(grey[700], 0.2)} !important`,
          backgroundImage: `linear-gradient(135deg,
            ${darken(grey[700], 0.2)} 30%,
            ${darken(grey[700], 0.3)} 30%,
            ${darken(grey[700], 0.3)} 70%,
            ${darken(grey[700], 0.4)} 70%,
            ${darken(grey[700], 0.4)} 90%,
            ${darken(grey[700], 0.5)} 90%,
            ${darken(grey[700], 0.5)} 100%) !important`, // close to background.default
          backgroundSize: "cover",
        },
      },
    },
    typography: openSansTypography,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 650,
          },
          containedPrimary: {
            color: indigo[900],
            "&:hover": {
              backgroundColor: yellow[400],
              color: "#000",
            },
          },
          containedSecondary: {
            color: indigo[900],
            "&:hover": {
              backgroundColor: indigo[300],
              color: "#000",
            },
          },
          textPrimary: {
            "&:hover": {
              backgroundColor: alpha(yellow[500], 0.08),
            },
          },
          textSecondary: {
            "&:hover": {
              backgroundColor: alpha(indigo[200], 0.08),
            },
          },
        },
        variants: [bigIconButton("#fff")],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: "rgba(255,255,255,0.025)",
            backdropFilter: "blur(24px)",
            border: "1px solid rgba( 255, 255, 255, 0.2 )",
          },
          elevation0: {
            background: "rgba(0, 0, 0, 0)",
            backdropFilter: "none",
            border: "none",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "& strong": {
              fontWeight: 800,
            },
          },
        },
        variants: [
          {
            props: { variant: "lede" },
            style: lede,
          },
        ],
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: yellow[200],
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: "#fff",
            background: alpha(grey[500], 0.125),
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: `${green[300]}`,
              color: green[900],
              fontWeight: 800,
            },
            "&.MuiPickersDay-today": {
              border: `2px solid #fff`,
            },
          },
        },
      },
    },
  },
  /*
 ██████╗██╗      █████╗ ███████╗███████╗██╗ ██████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝██║██╔════╝
██║     ██║     ███████║███████╗███████╗██║██║     
██║     ██║     ██╔══██║╚════██║╚════██║██║██║     
╚██████╗███████╗██║  ██║███████║███████║██║╚██████╗
╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝ ╚═════╝            */
  {
    name: "Classic",
    palette: {
      mode: "light",
      background: {
        default: grey[50],
        paper: "#fff",
        neutral: grey[200],
        neutralAlt: grey[800],
        array: [
          { background: grey[100], color: indigo[800] },
          { background: blue[600], color: "#ffffff" }, // primary
          { background: green[600], color: "#ffffff" }, // secondary
          { background: deepOrange[600], color: "#ffffff" }, // tertiary
          { background: deepPurple[600], color: "#ffffff" },
          { background: yellow[600], color: "#000000" },
          { background: blue[900], color: "#ffffff" },
        ],
      },
      primary: {
        main: blue[600],
        // also used manually for markdown link styles in index.css
        text: blue[800],
        contrastText: blue[100],
      },
      secondary: {
        main: green[600],
        text: green[800],
        contrastText: green[100],
      },
      tertiary: {
        main: deepOrange[600],
        text: deepOrange[800],
        contrastText: deepOrange[100],
      },
      neutral: neutral.lightMode,
      error: {
        main: red[500],
        text: red[800],
      },
      warning: {
        main: orange[500],
        text: orange[800],
      },
      info: {
        main: blue[500],
        text: blue[800],
      },
      success: {
        main: green[500],
        text: green[800],
      },
      charts: {
        first: blue[600], //primary
        second: green[600], //secondary
        third: deepOrange[600], //tertiary
        fourth: yellow[500], //quaternary
        fifth: deepPurple[500], //quinary
      },
      nav: {
        appBar: blue[600],
        main: blue[600],
        light: "#2c2c33",
        dark: "#1c1c1c",
        contrastText: "#fff",
        logoText: "#fff",
        logoO: "#fff",
        arc: "#ccc",
        hashes: "#000",
        arrowLight: "#de5433",
        arrowDark: "#a84026",
        selected: {
          background: alpha(blue[900], 0.32),
        },
        hover: {
          background: alpha(blue[900], 0.32),
        },
      },
      text: {
        primaryAlt: "rgba(255, 255, 255, 0.87)",
        secondaryAlt: "rgba(255, 255, 255, 0.6)",
      },
      pages: {
        moods: classicHeader("moods", grey[800], "#fff"),
        badges: classicHeader("badges", grey[900], "#fff"),
        goals: classicHeader("goals", blue[50], grey[900]),
        interests: classicHeader("interests", grey[700], grey[50]),
        profile: classicHeader("profile", blue[800], grey[50]),
        attendance: classicHeader("attendance", deepOrange[700], grey[50]),
        courses: classicHeader("courses", green[200], green[700]),
        journey: classicHeader("journey", brown[800], grey[50]),
        events: classicHeader("events", blue[800], "#fff"),
        activities: classicHeader("activities", "#000", "#fff"),
        buses: classicHeader("buses", yellow[200], grey[900]),
        forms: classicHeader("forms", deepPurple[900], "#fff"),
        covid: classicHeader("covid", red[900], grey[200]),
        personalization: classicHeader("personalization", grey[200], blue[700]),
        help: {
          ...classicHeader("help", blue[200], grey[900]),
          "& .MuiTypography-h1:first-of-type svg": {
            display: "none",
          },
        },
      },
      moods: standardMoods,
      badges: standardBadge,
      interests: interests,
      feed: {
        goal: {
          color: "#fff !important",
          backgroundColor: `${darken(green[800], 0.2)} !important`,
          backgroundImage: `linear-gradient(135deg,
            ${darken(green[800], 0.2)} 30%,
            ${darken(green[800], 0.3)} 30%,
            ${darken(green[800], 0.3)} 70%,
            ${darken(green[800], 0.4)} 70%,
            ${darken(green[800], 0.4)} 90%,
            ${darken(green[800], 0.5)} 90%,
            ${darken(green[800], 0.5)} 100%) !important`,
          backgroundSize: "cover",
        },
      },
    },
    typography: openSansTypography,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: grey[50],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 650,
          },
          textPrimary: {
            "&:hover": {
              backgroundColor: alpha(blue[900], 0.08),
            },
          },
          textSecondary: {
            "&:hover": {
              backgroundColor: alpha(green[900], 0.08),
            },
          },
        },
        variants: [bigIconButton(blue[700])],
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            border: "1px solid rgba( 255, 255, 255, 0.2 )", // though this is invisible, it makes paper alignment under tabs consistent with the other themes that have Paper styles
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "& strong": {
              fontWeight: 800,
            },
          },
        },
        variants: [
          {
            props: { variant: "lede" },
            style: lede,
          },
        ],
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#000",
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: grey[900],
            background: alpha(grey[500], 0.125),
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: `${green[300]}`,
              color: green[900],
              fontWeight: 800,
            },
            "&.MuiPickersDay-today": {
              border: `2px solid ${grey[400]}`,
            },
          },
        },
      },
    },
  },
];
