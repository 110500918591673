import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faMoneyBillWave,
  faShareNodes,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faFacebookSquare,
  faSquareXTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import TranslationInfo from "./TranslationInfo";
import AboutCompass from "./AboutCompass";
import SynergyLogo from "../images/icons/SynergyLogo";

export default function HomeSidebar() {
  const { user } = useUser();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Styles
  const sidebarRow1Styles = {
    display: "flex",
    justifyContent: largeScreen ? "auto" : "center",
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6} sx={sidebarRow1Styles}>
          <TranslationInfo browser={user.browser} />
        </Grid>
        <Grid item xs={6} sx={sidebarRow1Styles}>
          <AboutCompass />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={user?.role === "guardian" ? 6 : 12} md={12}>
          <Button
            startIcon={<SynergyLogo />}
            sx={{ textTransform: "none" }}
            size="large"
            href="https://wa-tacoma-psv.edupoint.com/PXP2_Login.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ fontSize: "1.25rem" }}>
              {user?.role === "student" ? "StudentVUE" : "ParentVUE"}&nbsp;
            </span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
          <Typography variant="caption" component="p">
            Visit your {user?.role === "guardian" && "child's "}
            <strong>virtual classrooms</strong> to see assignments
            and&nbsp;more.
          </Typography>
        </Grid>
        {user?.role === "guardian" && (
          <Grid item xs={12} sm={6} md={12}>
            <Button
              startIcon={<FontAwesomeIcon icon={faMoneyBillWave} />}
              sx={{ textTransform: "none" }}
              size="large"
              href="https://www.myschoolbucks.com/ver2/keysso?kc_idp_hint=MMW_11144"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontSize: "1.25rem" }}>MySchoolBucks&nbsp;</span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Button>
            <Typography variant="caption" component="p">
              Easily view/pay fines/fees and add funds for meal&nbsp;items.
            </Typography>
          </Grid>
        )}
      </Grid>
      {user?.role === "guardian" && (
        <>
          <Divider sx={{ my: 4 }} />
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faShareNodes}
                style={{
                  height: 22,
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
              />
              Social
            </Grid>
            <Grid item>
              <IconButton
                href="https://www.facebook.com/tacomaschools"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </IconButton>
              <IconButton
                href="https://twitter.com/tacomaschools"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/company/tacoma-public-school"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/tacomaschools/"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/channel/UCf50YFIXusxm-f6DRz9ypYA"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
