import {
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Grid,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { alpha, darken } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import dayjs from "dayjs";
import { formatDate } from "../../services/utilities";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglass,
  faHourglassEnd,
  faCircleCheck,
  faFlag,
  faStar as faSolidStar,
  faFlagPennant as faSolidFlagPennant,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faStar as faHollowStar,
  faFlagPennant as faHollowFlagPennant,
} from "@fortawesome/pro-regular-svg-icons";
import { usePatchGoalFavorite } from "../../services/hooks/studentGoalsHook";

export default function GoalListItem(props) {
  const theme = useTheme();
  const mediumPlusScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { goal } = props;
  const navigate = useNavigate();
  const patchFavorite = usePatchGoalFavorite(goal.studentGoalId);
  const commonStyles = useCommonStyles();
  const extraSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const isToday = require("dayjs/plugin/isToday");
  const isTomorrow = require("dayjs/plugin/isTomorrow");

  const badgeUrl = goal?.badge?.url;

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  // Styles
  const listItemStyles = {
    ...commonStyles.paperPadding,
    mb: theme.spacing(2),
  };
  const activeStyles = {
    ...listItemStyles,
  };
  const completedStyles = {
    ...listItemStyles,
    color: theme?.palette?.text?.secondary,
  };
  const milestoneIconWrapStyles = {
    display: "inline-block",
    ...commonStyles.iconOverBackground(),
  };
  const milestoneIconStyles = {
    padding: "0.125rem 0.25rem 0.125rem 0.35rem",
    borderTopRightRadius: "40%",
    borderBottomRightRadius: "60%",
    display: "inline-block",
    background: `radial-gradient(${alpha(
      theme?.palette?.background?.default ?? "#777",
      0.5
    )}, transprgba(0, 0, 0, 0)arent) no-repeat`,
  };
  const lateStyles = {
    "& p": {
      fontWeight: "800 !important",
      textTransform: "uppercase",
    },
  };
  const favoriteIconButtonStyles = {
    ...commonStyles.iconOverBackground(),
  };
  const favoriteIconStyles = {
    color: theme?.palette?.secondary?.text,
    background: `radial-gradient(${alpha(
      theme?.palette?.background?.default ?? "#777",
      0.75
    )} 0%,
    rgba(0, 0, 0, 0) 65%) no-repeat`,
  };
  const statusIconStyles = {
    ...commonStyles.iconOverBackground(40),
  };
  const successIconStyles = {
    color: theme?.palette?.success?.main,
    ...commonStyles.iconOverBackground(20),
    background: `radial-gradient(${alpha(
      theme?.palette?.background?.default,
      0.75
    )} 0%, ${alpha(theme?.palette?.background?.default ?? "#777", 0.4)} 40%,
    rgba(0, 0, 0, 0) 70%) no-repeat`,
    padding: "0.5rem",
    borderRadius: "50%",
  };
  const listItemTextStyles = {
    ...commonStyles.textOverBackground,
  };
  const completedListItemTextStyles = {
    ...listItemTextStyles,
    color: darken(
      theme?.palette?.getContrastText(theme?.palette?.background?.neutral),
      0.2
    ), // Should be close to a solid (without alpha transparency) version of theme.palette.text.secondary so it's not tinted by the shadow
  };

  function getStatusColor(date) {
    if (!!date) {
      const deadline = dayjs(date);
      const diff = deadline.diff(dayjs(), "day");

      if (diff <= 3 && diff >= 0) {
        return theme?.palette?.warning?.text;
      } else if (diff < 0) {
        return theme?.palette?.error?.text;
      } else return theme?.palette?.text?.primary;
    }
  }

  function getStatusLabel(date) {
    if (!!date) {
      const deadline = dayjs(date);
      const diff = deadline.diff(dayjs(), "day");

      if (diff < 0) {
        return "Late";
      } else if (deadline.isToday()) {
        return "Today";
      } else if (deadline.isTomorrow()) {
        return "Tomorrow";
      } else if (diff === 2 || diff === 3) {
        return "Soon";
      } else return "";
    }
  }

  return (
    <>
      <ListItemButton
        key={goal.studentGoalId}
        sx={!!goal?.completedDate ? completedStyles : activeStyles}
        disabled={patchFavorite.isLoading}
        onClick={() => {
          navigate(`/goals/${goal.studentGoalId}`);
        }}
        divider
      >
        {!extraSmallScreen && (
          <ListItemIcon>
            {!!goal?.completedDate ? (
              <Box sx={successIconStyles}>
                <FontAwesomeIcon icon={faCircleCheck} size="3x" />
              </Box>
            ) : (
              <Box sx={statusIconStyles}>
                <FontAwesomeIcon
                  icon={faFlag}
                  size="2x"
                  color={getStatusColor(goal.targetDate)}
                />
              </Box>
            )}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          sx={
            !!goal?.completedDate
              ? completedListItemTextStyles
              : listItemTextStyles
          }
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">{goal?.title}</Typography>
              {goal?.goalMilestones?.length > 0 && (
                <Typography variant="body2">
                  {goal?.goalMilestones.filter((gm) => gm.complete)?.length} of{" "}
                  {goal?.goalMilestones?.length}&nbsp;MILESTONES: &nbsp;
                  {goal?.goalMilestones?.map((gm, index) => {
                    const icon = !!gm.complete ? (
                      <Box
                        sx={milestoneIconWrapStyles}
                        component="span"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faSolidFlagPennant}
                          style={milestoneIconStyles}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={milestoneIconWrapStyles}
                        component="span"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faHollowFlagPennant}
                          style={milestoneIconStyles}
                          color={getStatusColor(gm.mileStoneDate)}
                        />
                      </Box>
                    );
                    return icon;
                  })}
                </Typography>
              )}
            </Grid>
            {badgeUrl != null ? (
              <Grid item xs={3}>
                <img
                  src={badgeUrl}
                  loading="lazy"
                  width={mediumPlusScreen ? 50 : 56}
                  height={mediumPlusScreen ? 50 : 63}
                  alt=""
                />
              </Grid>
            ) : (
              <Grid item xs={3}>
                <Box> </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Target Date</strong>
                  </Typography>
                </Grid>

                {getStatusLabel(goal.targetDate) !== "" &&
                  !goal?.completedDate && (
                    <Grid item>
                      <Box sx={statusIconStyles}>
                        <FontAwesomeIcon
                          icon={
                            getStatusLabel(goal.targetDate) === "Late"
                              ? faHourglassEnd
                              : faHourglass
                          }
                          size="2x"
                          color={getStatusColor(goal.targetDate)}
                        />
                      </Box>
                    </Grid>
                  )}
                <Grid
                  item
                  sx={
                    getStatusLabel(goal.targetDate) === "Late" ? lateStyles : {}
                  }
                >
                  <Typography
                    variant="body2"
                    color={
                      !!goal?.completedDate
                        ? goal.targetDate
                        : getStatusColor(goal.targetDate)
                    }
                  >
                    {formatDate(goal.targetDate)}
                    <br />
                    {!!goal?.completedDate
                      ? ""
                      : getStatusLabel(goal.targetDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton
            disabled={patchFavorite.isLoading || !!goal?.completedDate}
            onClick={(e) => {
              e.stopPropagation();
              patchFavorite.mutate(!goal.isFavorite);
            }}
            sx={favoriteIconButtonStyles}
          >
            <FontAwesomeIcon
              icon={goal.isFavorite ? faSolidStar : faHollowStar}
              style={favoriteIconStyles}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  );
}
