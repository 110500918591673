import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import {
  faBars,
  faChevronLeft,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme, darken } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompassLogo from "../images/CompassLogo";
import CompassLogoSmall from "../images/CompassLogoSmall";
import TpsLogo from "../images/TpsLogo";
import useNavItems from "./useNavItems";
import { useUser } from "../../services/contexts/userContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TrailFanLeftFadeIn from "../animations/TrailFanLeftFadeIn";

const drawerWidth = 280;

export default function Nav(props) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user, userLoading } = useUser();
  const navItems = useNavItems();
  const { instance } = useMsal();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const isSelected = (path) => {
    if (path === "/") {
      return Boolean(location?.pathname === "/");
    } else return Boolean(location?.pathname?.includes(path));
  };

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const GetNavItems = () => {
    /* 
      The array of nav elements must all be built at the  
      same time or the animation does not work properly. 
      This is ugly, but this is the only way I could get 
      it to work. I will answer to God when the time comes.
      -AT
    */
    var navItemElements = [];

    navItems
      ?.filter((item) => item.enabled === true)
      ?.map((navItems, index) => {
        navItemElements.push(
          <div key={index}>
            {navItems.name === "STUDENTS ONLY" ? (
              <Box>
                <ListItem
                  divider={false}
                  sx={[
                    navItemStyles,
                    {
                      background: "rgba(0, 0, 0, 0) !important",
                      pb: 0,
                      mb: "-6px",
                    },
                  ]}
                  selected={false}
                >
                  <ListItemText
                    primary={
                      <span
                        style={{
                          fontSize: "0.75rem",
                          opacity: 0.75,
                        }}
                      >
                        {navItems.name}
                      </span>
                    }
                  />
                </ListItem>
              </Box>
            ) : (
              userLoading === false &&
              user?.validUser === true && (
                <NavLink
                  to={navItems.to}
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    divider={
                      theme?.palette.nav?.main !== "rgba(0, 0, 0, 0)" &&
                      index === navItems.length - 1
                    }
                    button
                    sx={[
                      navItemStyles,
                      {
                        paddingLeft: navItems.studentsOnly ? "2.25rem" : "auto",
                      },
                    ]}
                    selected={isSelected(navItems.to)}
                  >
                    <ListItemIcon sx={navIconStyles}>
                      {navItems.icon && (
                        <FontAwesomeIcon icon={navItems.icon} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={navItems.name} />
                  </ListItem>
                </NavLink>
              )
            )}
            {Boolean(isSelected(navItems.to)) && (
              <Helmet>
                <title>
                  {navItems.name !== "STUDENTS ONLY"
                    ? `${navItems.name} | ${process.env.REACT_APP_NAME}`
                    : `${process.env.REACT_APP_NAME}`}
                </title>
              </Helmet>
            )}
          </div>
        );
      });

    navItemElements.push(
      <div key={-1}>
        <NavLink
          to={""}
          onClick={() => {
            setOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <ListItem button sx={navItemStyles} onClick={logout}>
            <ListItemIcon sx={navIconStyles}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText primary={"Log out"} />
          </ListItem>
        </NavLink>
      </div>
    );

    return navItemElements;
  };

  //Styles
  const envBannerHeight =
    process.env.REACT_APP_ENVIRONMENT === "production" ? 0 : 24;
  const envBannerStyles = {
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: "center",
    position: "fixed",
    zIndex: 99999,
    width: "100%",
    mt: `-${envBannerHeight}px`,
  };
  const appBarStyles = {
    background: Boolean(theme.palette.nav?.appBar)
      ? theme.palette.nav?.appBar
      : theme.palette.nav?.main,
    mt: `${envBannerHeight}px`,
  };
  const centerContentStyles = {
    display: "flex",
    justifyContent: "center",
  };
  const compassLogoStyles = {
    ...centerContentStyles,
    height: 100,
    mb: theme.spacing(2),
    mt: `${envBannerHeight}px`,
  };
  const drawerStyles = {
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
      background: smallScreen
        ? theme.palette.nav?.appBar
        : theme.palette.nav?.main,
      border: "none",
      overflowX: "hidden",
    },
    width: drawerWidth,
  };
  const footerStyles = {
    marginTop: "auto",
  };
  const navIconStyles = {
    color: theme.palette.nav?.contrastText
      ? darken(theme?.palette.nav?.contrastText ?? "#777", 0.1)
      : "grey",
    minWidth: "1.5rem",
    marginRight: "1rem",
    justifyContent: "center",
  };
  const openNavStyles = {
    ...navIconStyles,
    width: "3rem",
    height: "3rem",
    marginRight: "0.5rem",
  };
  const closeNavStyles = {
    ...navIconStyles,
    width: "3rem",
    height: "3rem",
    margin: "0.5rem",
    "& svg": {
      marginLeft: "-4px",
    },
  };
  const navItemStyles = {
    py: "5px",
    transition: ".4s ease all",
    color: theme.palette.nav?.contrastText,
    "&.Mui-selected": {
      ...theme.palette.nav?.selected,
      "&:hover": {
        background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.16) 100%), ${theme.palette.nav?.selected?.background}`,
      },
    },
    "&:hover": {
      background: Boolean(theme.palette?.nav?.hover?.background)
        ? theme.palette?.nav?.hover?.background
        : theme.palette?.nav?.dark,
    },
  };
  const pageWrapStyles = () => {
    // adding styles based on page or section
    const path = location?.pathname?.split("/");
    if (!!theme?.palette?.pages && !!path[1]) {
      return {
        minHeight: "100vh",
        ...theme.palette.pages[path[1]],
        mt: `${envBannerHeight}px`,
      };
    } else
      return {
        minHeight: "100vh",
        mt: `${envBannerHeight}px`,
      };
  };

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== "production" && (
        <Box sx={envBannerStyles}>{`${process.env.REACT_APP_ENVIRONMENT}`}</Box>
      )}

      <Box sx={pageWrapStyles}>
        <Box sx={{ display: "flex" }}>
          {smallScreen && (
            <AppBar position="fixed" open={open}>
              <Toolbar sx={appBarStyles}>
                <IconButton
                  sx={openNavStyles}
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <FontAwesomeIcon icon={faBars} />
                </IconButton>
                <NavLink to="/">
                  <CompassLogoSmall />
                </NavLink>
              </Toolbar>
            </AppBar>
          )}
          <Drawer
            sx={drawerStyles}
            variant={smallScreen ? "temporary" : "permanent"}
            anchor="left"
            open={open}
            ModalProps={{ onBackdropClick: handleDrawerClose }}
          >
            {smallScreen ? (
              <>
                <IconButton onClick={handleDrawerClose} sx={closeNavStyles}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <Divider />
              </>
            ) : (
              <Box sx={compassLogoStyles}>
                <NavLink to="/">
                  <CompassLogo />
                </NavLink>
              </Box>
            )}
            <HelmetProvider>
              <List>
                {userLoading ? (
                  <ListItem button sx={navItemStyles} onClick={logout}>
                    <ListItemIcon sx={navIconStyles}>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </ListItemIcon>
                    <ListItemText primary={"Log out"} />
                  </ListItem>
                ) : (
                  <TrailFanLeftFadeIn>{GetNavItems()}</TrailFanLeftFadeIn>
                )}
              </List>
            </HelmetProvider>
            <List sx={footerStyles}>
              <ListItem sx={centerContentStyles}>
                <TpsLogo theme={theme} />
              </ListItem>
            </List>
          </Drawer>
          <Box component="main" sx={commonStyles.contentBox}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
}
