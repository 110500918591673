//#region Imports
import React from "react";
import {
  Badge,
  Card,
  CardActionArea,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCalendarRange,
  faHandshakeSimple,
  faLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "../../services/utilities";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { truncateText, widowUnmaker } from "../../services/utilities";
//#endregion

export default function ActivityListItem(props) {
  const { program, pid, matchingProgram, matchingWaitlist } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const hasActiveWaitlist = program?.hasActiveWaitlist;

  const capacity = program.maxEnrollment - program.participantCount;

  const regEnddate = dayjs(program?.registrationEndDate);

  const isProgramWaitlisted = (program) => {
    if (program?.hasOwnProperty("waitlists")) {
      return true;
    } else {
      return false;
    }
  };

  const renderTitleWithBadge = (title) => {
    const today = dayjs(dayjs().format("YYYY-MM-DD"));
    const titleArray = title.split(" ");

    return (
      <Typography variant="h4" component="h2" color="primary" sx={titleStyles}>
        {titleArray?.map((word, index) => {
          if (
            today > regEnddate ||
            program.registrationEndDate === null ||
            (today <= regEnddate && index !== titleArray.length - 1)
          ) {
            return `${word + " "}`;
          }
          return null;
        })}
        {today < regEnddate.add(1, "day") && (
          <span
            style={{
              display: "inline-block",
              paddingRight: "6rem",
              minWidth: "200px",
            }}
          >
            <Badge
              badgeContent={
                <span style={{ whiteSpace: "nowrap" }}>
                  {matchingProgram || pid ? (
                    <strong>Enrolled</strong>
                  ) : isProgramWaitlisted(program) || !!matchingWaitlist ? (
                    <strong>On the waitlist</strong>
                  ) : capacity <= 0 || !!hasActiveWaitlist ? (
                    <strong>Waitlist available</strong>
                  ) : (
                    <strong>
                      {capacity === 1
                        ? `${capacity} spot available`
                        : `${capacity} spots available`}
                    </strong>
                  )}
                </span>
              }
              color={
                isProgramWaitlisted(program) || !!matchingWaitlist
                  ? "success"
                  : capacity <= 0 || !!hasActiveWaitlist
                  ? "error"
                  : "secondary"
              }
            >
              <span>{titleArray[titleArray?.length - 1]}</span>
            </Badge>
          </span>
        )}
      </Typography>
    );
  };

  //#region Styles
  const cardStyles = {
    my: theme.spacing(2),
  };
  const titleStyles = {
    mt: "0.75rem",
    "& span > span": {
      display: "flex",
      alignItems: "flex-end",
    },
  };
  const categoryStyles = {
    position: "absolute",
    backgroundColor: alpha(theme?.palette?.neutral?.darkest ?? "#777", 0.5),
    color: theme?.palette?.neutral?.light,
    py: "0.25rem",
    pl: "0.5rem",
    pr: "0.375rem",
    borderBottomLeftRadius: "0.5rem",
    right: 0,
    top: 0,
  };
  const detailIconStyles = {
    width: "1.5rem",
  };
  //#endregion

  //#region Render
  return (
    <Card sx={cardStyles}>
      <CardActionArea
        sx={commonStyles.paperPadding}
        onClick={() => {
          navigate(`/activities/${program.programId}`);
        }}
      >
        {program.activityTypeName && (
          <Typography variant="caption" component="div" sx={categoryStyles}>
            {program.activityTypeName}
          </Typography>
        )}
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            {renderTitleWithBadge(program.title ?? program.activityName)}
            {program.isFixedCost && program.cost > 0 && (
              <Typography variant="h5" component="p">
                ${program.cost} <em>required</em> cost
              </Typography>
            )}
            <Typography variant="subtitle1" style={{ maxWidth: "100%" }}>
              {truncateText(program?.description, 200)}
            </Typography>
          </Grid>

          <Grid item md={6}>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faLocationDot} style={detailIconStyles} />{" "}
              <strong>Location</strong>:{" "}
              {widowUnmaker(
                program?.locationName
                  ? program.locationName
                  : program?.location?.locationName
              )}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2">
              <FontAwesomeIcon
                icon={faHandshakeSimple}
                style={detailIconStyles}
              />{" "}
              <strong>Provider</strong>: {widowUnmaker(program.provider)}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2">
              <FontAwesomeIcon
                icon={faCalendarRange}
                style={detailIconStyles}
              />{" "}
              <strong>Start &amp; End&nbsp;Dates</strong>:{" "}
              {formatDate(program.startDate)}&nbsp;-&nbsp;
              {formatDate(program.endDate)}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faCalendarDays} style={detailIconStyles} />{" "}
              <strong>Days</strong>:{" "}
              {widowUnmaker(
                program?.cycleName
                  ? program?.cycleName
                  : program?.cycle?.cycleName
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
  //#endregion
}
