//#region Imports
import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router";
import { alpha } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import Error from "../common/Error";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarRange } from "@fortawesome/pro-solid-svg-icons";
import { formatDate, formatDateTime } from "../../services/utilities";
import dayjs from "dayjs";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ActivitiesTitle from "./ActivitiesTitle";
import { useDsaPartnerDetails } from "../../services/hooks/partnersHook";
import { useUser } from "../../services/contexts/userContext";
import { widowUnmaker } from "../../services/utilities";
import DsaRegistration from "../../components/common/DsaRegistration";
import DsaWithdraw from "../../components/common/DsaWithdraw";
//#endregion

export default function DsaPartnerDetails() {
  const { partnerId } = useParams();
  const { user } = useUser();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const commonStyles = useCommonStyles();
  const { activeStudent } = useActiveStudent();

  const [regDialogOpen, setRegDialogOpen] = useState(false);

  const onCloseRegDialog = () => {
    setRegDialogOpen(false);
  };

  const [withdrawalDialogOpen, setWithdrawalDialogOpen] = useState(false);
  const onCloseWithdrawalDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setWithdrawalDialogOpen(false);
  };

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const onCloseRemoveDialog = () => {
    setRemoveDialogOpen(false);
  };

  const query = useDsaPartnerDetails(partnerId);
  const details = query?.data?.data;

  function isRegistrationOpen(details) {
    if (!details?.dsaRegistrationEndDate) {
      return false;
    }
    const currentDate = dayjs().format("YYYY-MM-DD");
    const registrationEndDate = dayjs(details.dsaRegistrationEndDate).format(
      "YYYY-MM-DD"
    );
    return currentDate <= registrationEndDate;
  }

  const regButtonProps = useMemo(() => {
    if (details == null)
      return {
        text: "Loading",
        disabled: true,
        onClick: () => {},
      };
    else if (
      dayjs().format("YYYY-MM-DD") >
      dayjs(details.dsaEndDate).format("YYYY-MM-DD")
    ) {
      return {
        text: "Program has ended",
        disabled: true,
        onClick: () => {},
      };
    } else if (
      details.registrants.some(
        (r) =>
          r.studentId === activeStudent.studentId &&
          r.partnerId === parseInt(partnerId) &&
          r.withdrawalDate === null
      ) &&
      dayjs(details.dsaEndDate) > dayjs()
    ) {
      return {
        text: "Withdraw",
        disabled: false,
        onClick: () => {
          setWithdrawalDialogOpen(true);
        },
      };
    } else if (
      dayjs(details.dsaRegistrationStartDate).format("YYYY-MM-DD") <=
        dayjs().format("YYYY-MM-DD") &&
      dayjs(details.dsaRegistrationEndDate).format("YYYY-MM-DD") >=
        dayjs().format("YYYY-MM-DD") &&
      (details.registrants.every(
        (r) => r.studentId !== activeStudent.studentId
      ) ||
        details.registrants.some(
          (r) =>
            (r.studentId === activeStudent.studentId &&
              r.partnerId === partnerId &&
              r.withdrawalDate !== null) ||
            r.withdrawalDate?.length > 0
        ))
    ) {
      return {
        text: "Register",
        disabled: false,
        onClick: () => {
          setRegDialogOpen(true);
        },
      };
    } else {
      return {
        text: "Not open for registration",
        disabled: true,
        onClick: () => {},
      };
    }
  }, [details]);

  const renderGrades = (grades) => {
    // list returned from API is in alpha order and numbers have leading zeros
    let list = [];
    grades?.forEach((grade) => {
      if (grade[0] === "K") {
        // getting the first character only should handle occassional odd data like "K3"
        list.unshift("K");
      } else if (grade[0] === "P") {
        list.unshift("P");
      } else if (!parseInt(grade)) {
        list.unshift(grade); // handles any alpha grade that doesn't start with K or P, and puts all its characters at the front (TK, for example)
      } else list.push(parseInt(grade).toString());
    });
    return widowUnmaker(list?.join(", "));
  };

  //#region Styles
  const registrationWrapperStyles = {
    ...commonStyles.paperPadding,
    maxWidth: 375,
    minWidth: 300,
    mb: largeScreen ? 2 : 3,
    mt: 2,
    ml: largeScreen ? 3 : "auto",
    mr: largeScreen ? 0 : "auto",
    float: largeScreen ? "right" : "none",
    background: theme?.palette?.background?.neutral,
  };
  const detailsWrapperStyles = {
    mt: 3,
    "& p": {
      mb: 2,
      mx: 1,
    },
  };
  const detailIconStyles = {
    width: "1.5rem",
  };
  const fixedCost = {
    backgroundColor: alpha(theme?.palette?.neutral?.light, 0.1),
    py: "0.8rem",
    px: "1rem",
    width: "fit-content",
    borderRadius: "6px",
  };
  //#endregion

  //#region Render
  return (
    <>
      <ActivitiesTitle />

      {query.isLoading && <Skeleton />}
      {query.isError && (
        <Error message="There was an issue loading details for this program." />
      )}

      {/* user not allowed to see details */}
      {query.isSuccess && details === null && (
        <Typography>This program's details are unavailable.</Typography>
      )}

      {query.isSuccess && details && (
        <>
          <Paper sx={registrationWrapperStyles}>
            {user?.role === "guardian" ? (
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={regButtonProps?.disabled}
                onClick={regButtonProps?.onClick}
              >
                {regButtonProps?.text}
              </Button>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Typography>
                  <strong>Interested?</strong>
                </Typography>
                <Typography variant="body2">
                  Ask your parent/guardian to login to Compass and
                  register&nbsp;you.
                </Typography>
              </Box>
            )}
            {!!details.registrationStartDate &&
              !!details.registrationEndDate && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ textAlign: "center" }}
                >
                  Registration open:{" "}
                  {formatDateTime(details.registrationStartDate)} -{" "}
                  {formatDate(details.registrationEndDate)}&nbsp;11:59pm
                </Typography>
              )}
          </Paper>
          <Typography variant="h2">{details.dsaTitle}</Typography>
          <Typography
            variant="subtitle1"
            component="div"
            className={`markdown theme-${theme?.name?.toLowerCase()}`}
            sx={{ maxWidth: "100% !important" }}
          >
            <Markdown remarkPlugins={[remarkGfm]}>
              {details.dsaDescription}
            </Markdown>
          </Typography>

          <Grid
            container
            justifyContent="space-between"
            sx={detailsWrapperStyles}
          >
            <Grid item xs={12} md={4}>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faCalendarRange}
                  style={detailIconStyles}
                />{" "}
                <strong>Start &amp; End Dates</strong>:{" "}
                {formatDate(details.dsaStartDate)}&nbsp;-&nbsp;
                {formatDate(details.dsaEndDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </>
      )}

      <DsaRegistration
        open={regDialogOpen}
        onClose={onCloseRegDialog}
        details={details}
      />
      <DsaWithdraw
        open={withdrawalDialogOpen}
        onClose={onCloseWithdrawalDialog}
        registrant={details?.registrants.find(
          (r) => r.studentId === activeStudent.studentId
        )}
      />
    </>
  );
  //#endregion
}
